import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/layout/Seo"
import { graphql, Link } from "gatsby"
import ProductGrid from "../components/products/ProductGrid"
import FilterPanel, { handleFacetClick } from "../components/products/FilterPanel"
import client from "../helpers/AlgoliaClient"
import { PageGetSurrounding } from "../helpers/PaginationHelpers"
import IconsProductPreloader from "../components/products/IconsProductPreloader"

class CategoryPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: props.pageContext.page,
      facets: this.cleanFacets(this.props.data.facets),
      hits: this.props.data.items.nodes,
      pageInfo: this.props.data.items.pageInfo,
      queryParameters: {
        hitsPerPage: 36,
        facets: ["*"],
      },
      facetFilters: {
        "categories.id": ["categories.id:" + this.props.pageContext.categoryId],
      },
    }
    this.refreshData = this.refreshData.bind(this)
    this.handlePageClick = this.handlePageClick.bind(this)
    this.client = client()
    this.expand = prop => {
      const c = this.state.expanded
      if (prop in c) {
        delete c[prop]
      } else {
        c[prop] = true
      }
      this.setState({ expanded: c })
    }
    this.handleFacet = (key, facet) => {
      const filters = handleFacetClick(key, facet, this.state.facetFilters)
      this.setState({ facetFilters: filters })
      this.refreshData({}, filters)
    }
  }

  cleanFacets(facets) {
    const cleaned = {}
    for (const facet in facets) {
      if (!facets.hasOwnProperty(facet)) continue
      let cleanedArray = facets[facet].filter(info => info && (info.name || "").length > 0)
      if (cleanedArray && cleanedArray.length > 0) {
        cleaned[facet] = cleanedArray
      }
    }
    return cleaned
  }

  mergeFacet(facets, facet, filters, searchResponse) {
    if (filters && facet in filters && facet in (this.state.facets || {})) {
      facets[facet] = this.state.facets[facet]
    } else {
      facets[facet] = []
      for (let facetOption in searchResponse.facets[facet]) {
        if (!searchResponse.facets[facet].hasOwnProperty(facetOption)) continue
        if ((facetOption || "").length === 0) {
          continue
        }
        facets[facet].push({ name: facetOption, count: searchResponse.facets[facet][facetOption] })
      }
    }
    if ((facets[facet] || []).length <= 1) {
      delete facets[facet]
    }
  }

  mergeFacets(searchResponse, filters) {
    let facets = {}
    for (let facet in searchResponse.facets) {
      if (!searchResponse.facets.hasOwnProperty(facet)) continue
      this.mergeFacet(facets, facet, filters, searchResponse)
    }
    return facets
  }

  refreshData(props, facetFilters) {
    const ff = []
    if (facetFilters) {
      for (let key in facetFilters) {
        if (!facetFilters.hasOwnProperty(key)) continue
        ff.push(facetFilters[key])
      }
    }
    const searchProps = {
      ...this.state.queryParameters,
      ...props,
      facetFilters: ff,
    }
    const index = this.client.initIndex("production_products")
    let p = index.search("", searchProps)
    p.then(searchResponse => {
      // ** Map response facets to facet arrays
      const facets = this.mergeFacets(searchResponse, facetFilters)

      this.setState({
        hits: searchResponse.hits,
        page: searchResponse.page,
        facets: facets,
        pageInfo: {
          currentPage: searchResponse.page + 1,
          perPage: searchResponse.hitsPerPage,
          totalCount: searchResponse.nbHits,
          itemCount: searchResponse.hits.length,
        },
      })
    })
  }

  handlePageClick(e, page) {
    if (this.state.pageInfo.totalCount === this.props.data.items.pageInfo.totalCount) {
      return
    }
    e.preventDefault()
    this.refreshData({ page: page - 1 }, this.state.facetFilters)
  }

  render() {
    const pageInfo = this.state.pageInfo || this.props.data.items.pageInfo
    const category = this.props.data.category
    const injectPageNumber = (meta_title, page) => {
      if (meta_title.indexOf(" | ") > -1) {
        return meta_title.replace(" | ", ` #${page} | `)
      }
      return meta_title + ` #${page}`
    }
    const injectPageNumberDesc = (meta_description, category, page) => {
      if (page <= 1) return meta_description
      if (meta_description.indexOf(" | ") > -1) {
        return meta_description.replace(" | ", ` #${page} | `)
      }
      return `${category} #${page} | ${meta_description}`
    }
    if (!category.meta_title || category.meta_title === "NULL") {
      category.meta_title = category.name
    }
    if (!category.meta_description || category.meta_description === "NULL") {
      category.meta_description = category.name
    }
    const pages = PageGetSurrounding(pageInfo.currentPage, pageInfo.perPage, pageInfo.totalCount)
    const page = this.props.pageContext.page + 1

    return (
      <Layout breadcrumbs={this.props.pageContext.breadcrumbs} shop={true}>
        <Seo
          title={injectPageNumber(category.meta_title || category.name, page)}
          canonical={"/" + this.props.pageContext.url + (page > 1 ? `/page-${page}/` : "/")}
          description={injectPageNumberDesc(category.meta_description, category.name, page)}
        />
        <IconsProductPreloader />
        <div className={"flex flex-row w-11/12 max-w-[1366px] mx-auto"}>
          <h1 className={"py-6 flex-grow"}>
            {this.props.pageContext.name === "New Arrivals"
              ? "New Purchases"
              : this.props.pageContext.name}
          </h1>
          <span>
            Items {pageInfo.perPage * (pageInfo.currentPage - 1) + 1} to{" "}
            {Math.min(
              pageInfo.totalCount,
              pageInfo.perPage * (pageInfo.currentPage - 1) + pageInfo.itemCount
            )}{" "}
            of {pageInfo.totalCount} total
          </span>
        </div>
        <div className={"flex flex-col md:flex-row w-11/12 max-w-[1366px] mx-auto"}>
          <aside
            className={"md:w-1/5 p-4 pl-0 flex-none"}
            itemScope
            itemType="https://schema.org/WPSideBar"
          >
            <FilterPanel
              facets={this.state.facets}
              facetFilters={this.state.facetFilters}
              handleFacet={this.handleFacet}
            />
          </aside>
          <div className={"md:w-4/5 p-4 flex-none "}>
            <ProductGrid products={this.state.hits} />
          </div>
        </div>
        <div className={"flex flex-row justify-center my-2"}>
          Page
          {pages.map(page => {
            return (
              <>
                {page === 1 || pages.find(p => p === page - 1) ? (
                  ""
                ) : (
                  <div className={"px-4"}>...</div>
                )}
                <div className={"px-4 " + (page === pageInfo.currentPage ? "font-bold" : "")}>
                  <Link
                    to={"/" + category.url + (page > 1 ? "/page-" + page : "") + "/"}
                    onClick={e => {
                      this.handlePageClick(e, page)
                    }}
                  >
                    {page}
                  </Link>
                </div>
              </>
            )
          })}
        </div>
      </Layout>
    )
  }
}
// W525187, W525190, W525160
export const query = graphql`
  query ($categoryId: Int!, $perPage: Int!, $skip: Int!) {
    facets: allStrapiProduct(
      sort: { fields: stock_date, order: DESC }
      filter: { categories: { elemMatch: { id: { eq: $categoryId } } } }
    ) {
      age_attribute: group(field: age_attribute) {
        count: totalCount
        name: fieldValue
      }
      price_filter: group(field: price_filter) {
        count: totalCount
        name: fieldValue
      }
      case_material: group(field: case_material) {
        count: totalCount
        name: fieldValue
      }
      gender_attribute: group(field: gender_mfu) {
        count: totalCount
        name: fieldValue
      }
      band_metal: group(field: band_metal) {
        count: totalCount
        name: fieldValue
      }
      head_metal: group(field: head_metal) {
        count: totalCount
        name: fieldValue
      }
      brand: group(field: brand) {
        count: totalCount
        name: fieldValue
      }
      band_color: group(field: band_color) {
        count: totalCount
        name: fieldValue
      }
      band_type: group(field: band_type) {
        count: totalCount
        name: fieldValue
      }
      case_shape: group(field: case_shape) {
        count: totalCount
        name: fieldValue
      }
      case_size: group(field: case_size) {
        count: totalCount
        name: fieldValue
      }
      model: group(field: model) {
        count: totalCount
        name: fieldValue
      }
    }
    items: allStrapiProduct(
      sort: { fields: stock_date, order: DESC }
      limit: $perPage
      skip: $skip
      filter: { categories: { elemMatch: { id: { eq: $categoryId } } } }
    ) {
      nodes {
        name
        productType
        categories {
          name
          id
        }
        url_key
        sku
        title
        small_image {
          name
          url
        }
        thumbnail {
          name
          url
        }
        brand
        model
        price
      }
      pageInfo {
        perPage
        pageCount
        totalCount
        itemCount
        currentPage
        hasNextPage
        hasPreviousPage
      }
    }
    category: strapiCategory(strapiId: { eq: $categoryId }) {
      meta_title
      meta_description
      name
      description
      url
    }
  }
`

export default CategoryPage
